// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCTDjHpH8gQt9R9d2E5TYYLpVw6pCs9nCk",
  authDomain: "flux-inventario.firebaseapp.com",
  databaseURL: "https://flux-inventario.firebaseio.com",
  projectId: "flux-inventario",
  storageBucket: "flux-inventario.appspot.com",
  messagingSenderId: "628024238388",
  appId: "1:628024238388:web:31e030286cfe4696198561",
  measurementId: "G-ZYCGPDFWTZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
//connectFunctionsEmulator(functions, "127.0.0.1", 3001);

export { functions };
