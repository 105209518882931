import { functions } from "./firebase";
import SearchResponse from "./types";
import { httpsCallable } from "firebase/functions";

const searchProductFunction = httpsCallable<unknown, SearchResponse>(
  functions,
  "searchProduct",
);

const searchProduct = async (itemId: string): Promise<SearchResponse> => {
  const data = await searchProductFunction({ itemId });
  return data.data;
};

export default searchProduct;
