import React from "react";
import logo from "./logo.png";
import "./App.scss";
import { ChakraProvider, Box, Center } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import SearchForm from "./SearchForm";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider>
        <Box w="100%" backgroundColor="#ec2031">
          <Center>
            <img src={logo} alt="Logo" className="logo" />
          </Center>
        </Box>
        <Box w="100%" h="60px" backgroundColor="black" />
        <SearchForm />
      </ChakraProvider>
    </QueryClientProvider>
  );
}

export default App;
