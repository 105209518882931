import React, { FC, ReactElement, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardBody,
  Container,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { useQuery } from "@tanstack/react-query";
import searchProduct from "./api";

/**
 * SearchForm Component
 *
 * @returns {React.ReactElement} SearchForm.
 */
const SearchForm: FC = (): ReactElement => {
  const [code, setCode] = useState("");
  const { data, refetch, isSuccess, isRefetching, isLoading } = useQuery({
    queryKey: ["searchProduct"],
    queryFn: () => searchProduct(code),
    enabled: false,
  });

  return (
    <Container maxW="container.xl" pt={10} pb={10}>
      <Heading mb={2}>Introduce el codigo del producto:</Heading>
      <Stack direction={["column", "row"]}>
        <Box w={["100%", "80%"]}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Codigo"
              type="search"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  if (!isRefetching && !isLoading) {
                    refetch();
                  }
                }
              }}
            />
          </InputGroup>
        </Box>
        <Box w={["100%", "20%"]}>
          <Button
            colorScheme="red"
            width="100%"
            isLoading={isRefetching || isLoading}
            onClick={() => {
              refetch();
            }}
          >
            Buscar
          </Button>
        </Box>
      </Stack>
      {isSuccess && (
        <Card mt={10}>
          <CardBody>
            <Heading as="h3" size="lg">
              {data.producto}
            </Heading>
            <Heading as="h3" size="sm" color="gray.500">
              Disponibles {data.total}
            </Heading>
            {data?.localidades?.map((loc) => (
              <Box mt={5}>
                <Heading as="h3" size="md" mb={2}>
                  {loc.localidad}
                </Heading>
                <Card>
                  <TableContainer mt={5}>
                    <Table size="sm">
                      <Thead>
                        <Tr>
                          <Th>Bodega</Th>
                          <Th>Unidades</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {loc.bodegas.map((bodega) => (
                          <Tr>
                            <Td>{bodega.bodega}</Td>
                            <Td>{bodega.total}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Card>
              </Box>
            ))}
          </CardBody>
        </Card>
      )}
    </Container>
  );
};

export default SearchForm;
